import { Button, HStack, Heading, Stack } from '@chakra-ui/react';
import {
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  rectIntersection,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { AuthenticationModal } from '$/components/core/Authentication/AuthenticationModal';
import { MaterialCard } from '$/components/core/Collection/MaterialCard';
import { FilterTags } from '$/components/core/Collection/MaterialPresentation/DataSortingRow/components/FilterTags';
import { DnDContext } from '$/components/core/DragAndDrop/DnDContext';
import { FavoriteItem } from '$/pages/DashboardPages/pages/Favorite';
import { FavoriteSortableGroup } from '$/pages/DashboardPages/pages/Favorite/components/FavoriteSortableGroup';
import { useGroupedFavorites } from '$/pages/DashboardPages/pages/Favorite/hooks/useGroupedFavorites';
import { MaterialType } from '$/services/mapper/uses';
import { changeFolderOrder } from '$/services/usecases/favorites';
import { FavoriteFolderSection } from '$/services/usecases/favorites/mapper/getFavoritesResponse';
import {
  FavoriteSectionGroup,
  useFavoriteStore,
} from '$/stores/useFavoriteStore';
import { uid } from '$/utils/uid';

const materialTypes = ['uni', 'wall', 'floor', 'facade'] as const;

export const FavoriteFolderView = () => {
  const { t } = useTranslation();
  const setActiveFolderId = useFavoriteStore.useSetActiveFolderId();
  const activeFolderId = useFavoriteStore.useActiveFolderId();
  const folders = useFavoriteStore.useFavoriteFolders();
  const globalFavoriteFolders = useFavoriteStore.useGlobalFavoriteFolders();
  const latestOverContainer = useRef<string | null>(null);
  const [draggedFavorite, setDraggedFavorite] = useState<FavoriteItem | null>(
    null,
  );
  const folderSectionGroups = useFavoriteStore.useFolderSectionGroups();
  const getFavorites = useFavoriteStore.useGetFavorites();

  const setFolderSectionGroups = (
    folderSectionGroups: Partial<Record<MaterialType, FavoriteSectionGroup[]>>,
  ) => {
    useFavoriteStore.setState({ folderSectionGroups });
  };

  const currentFolder =
    folders.find((folder) => folder.id === activeFolderId) ??
    globalFavoriteFolders.find((folder) => folder.id === activeFolderId);

  const {
    colorFavorites,
    floorFavorites,
    wallFavorites,
    facadeFavorites,
    allFavorites,
    isLoading,
  } = useGroupedFavorites(
    activeFolderId!,
    [],
    true,
    undefined,
    currentFolder?.materialOrder,
  );

  const getRelevantMaterialsByType = (materialType: MaterialType) => {
    switch (materialType) {
      case 'uni':
        return colorFavorites;
      case 'wall':
        return wallFavorites;
      case 'floor':
        return floorFavorites;
      case 'facade':
        return facadeFavorites;
      default:
        return [];
    }
  };

  useEffect(() => {
    if (currentFolder == null || currentFolder.sections == null) {
      setFolderSectionGroups({});
      return;
    }

    const materialSections = materialTypes.reduce<
      Record<MaterialType, FavoriteFolderSection[]>
    >(
      (acc, type) => {
        const sections = currentFolder.sections!.filter(
          (section) => section.materialType === type,
        );

        if (sections.length === 0) {
          sections.unshift({
            id: uid(),
            materialType: type,
            favoriteIds: getRelevantMaterialsByType(type).map(
              (favorite) => favorite.material.uniqueKey,
            ),
          });
        }

        const materialsWithoutSection = getRelevantMaterialsByType(type).filter(
          (material) =>
            !sections.some((section) =>
              section.favoriteIds.includes(material.material.uniqueKey),
            ),
        );

        // add materials without section to last section
        if (materialsWithoutSection.length > 0) {
          const lastSection = sections[sections.length - 1];
          lastSection.favoriteIds.push(
            ...materialsWithoutSection.map(
              (material) => material.material.uniqueKey,
            ),
          );
        }

        acc[type] = sections;
        return acc;
      },
      {} as Record<MaterialType, FavoriteFolderSection[]>,
    );

    const newSectionGroups = (
      Object.keys(materialSections) as MaterialType[]
    ).reduce<Record<MaterialType, FavoriteSectionGroup[]>>(
      (acc, type) => {
        const sections = materialSections[type];
        const materials = getRelevantMaterialsByType(type);

        if (materials.length === 0) return acc;

        const groups = sections.map((section) => {
          const favorites = getRelevantMaterialsByType(type).filter(
            (favorite) =>
              section.favoriteIds.includes(favorite.material.uniqueKey),
          );
          return {
            id: section.id,
            title: section.title,
            materialType: type,
            favorites,
          };
        });

        acc[type] = groups;
        return acc;
      },
      {} as Record<MaterialType, FavoriteSectionGroup[]>,
    );

    setFolderSectionGroups(newSectionGroups);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActiveFolderId, currentFolder?.sections, isLoading]);

  const editMode = currentFolder?.isGlobalFavoriteFolder
    ? 'none'
    : 'favoriteExtended';

  const onDragStart = (event: DragStartEvent) => {
    const favorite = allFavorites.find(
      (favorite) => favorite.material.uniqueKey === event.active.id,
    );
    setDraggedFavorite(favorite ?? null);
  };

  const onDragEnd = async (event: DragEndEvent) => {
    latestOverContainer.current = null;
    setDraggedFavorite(null);

    const activeContainer = getContainer(event.active.id.toString());

    if (activeContainer == null || event.over?.id === activeContainer.id)
      return;

    const groups = { ...folderSectionGroups };
    if (groups[activeContainer.materialType] != null) {
      groups[activeContainer.materialType] = groups[
        activeContainer.materialType
      ]?.map((section) => {
        if (section.id !== activeContainer.id) return section;
        const startIndex = section.favorites.findIndex(
          (favorite) => favorite.material.uniqueKey === event.active.id,
        );
        const endIndex = section.favorites.findIndex(
          (favorite) => favorite.material.uniqueKey === event.over?.id,
        );

        return {
          ...section,
          favorites: arrayMove(section.favorites, startIndex, endIndex),
        };
      });
    }

    setFolderSectionGroups(groups);

    const { materialOrdering, sections } = Object.values(groups).reduce<{
      materialOrdering: string[];
      sections: FavoriteFolderSection[];
    }>(
      (acc, group) => {
        const materials: FavoriteItem[] = [];

        group.forEach((section) => {
          acc.sections.push({
            id: section.id,
            title: section.title,
            materialType: section.materialType,
            favoriteIds: section.favorites.map(
              (item) => item.material.uniqueKey,
            ),
          });
          materials.push(...section.favorites);
        });

        acc.materialOrdering.push(
          ...materials.map((item) => item.material.uniqueKey),
        );

        return acc;
      },
      {
        materialOrdering: [],
        sections: [],
      },
    );

    await changeFolderOrder(activeFolderId!, materialOrdering, sections);
    await getFavorites(true);
  };

  const getContainer = (id: string) => {
    return Object.values(folderSectionGroups)
      .flat()
      .find(
        (section) =>
          section.id === id ||
          section.favorites.find((item) => item.material.uniqueKey === id),
      );
  };

  const onDragOver = (event: DragOverEvent) => {
    if (
      event.over == null ||
      event.over.id === event.active.id ||
      !draggedFavorite
    )
      return;

    const startContainer = getContainer(
      latestOverContainer.current ?? event.active.id.toString(),
    );
    const endContainer = getContainer(event.over.id.toString());

    const containersNotFound = !startContainer || !endContainer;
    const sameContainer = startContainer?.id === endContainer?.id;
    const differentMaterialType =
      startContainer?.materialType !== endContainer?.materialType;

    if (containersNotFound || sameContainer || differentMaterialType) return;

    latestOverContainer.current = event.over.id.toString();

    const newGroups = { ...folderSectionGroups };
    newGroups[startContainer.materialType] = newGroups[
      startContainer.materialType
    ]?.map((section) => ({
      ...section,
      favorites: section.favorites.filter(
        (favorite) =>
          favorite.material.uniqueKey !== draggedFavorite.material.uniqueKey,
      ),
    }));

    newGroups[endContainer.materialType] = newGroups[
      endContainer.materialType
    ]?.map((section) => {
      if (section.id !== endContainer.id) return section;
      return {
        ...section,
        favorites: [...section.favorites, draggedFavorite],
      };
    });

    setFolderSectionGroups(newGroups);
  };

  const getSortableFavoriteGroup = (
    headline: string,
    materialType: MaterialType,
  ) => {
    return (
      <DnDContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        dragPlaceholder={
          draggedFavorite ? (
            <MaterialCard
              material={draggedFavorite.material}
              opacity={0.6}
              editMode='none'
            />
          ) : undefined
        }
        collisionDetection={rectIntersection}
        restrictToSpecificParentId={`${materialType}-group`}
      >
        <FavoriteSortableGroup
          headline={headline}
          isLoading={isLoading}
          materialType={materialType}
          editMode={editMode}
          folderSections={folderSectionGroups[materialType]}
          isDragDisabled={currentFolder?.isGlobalFavoriteFolder}
        />
      </DnDContext>
    );
  };

  return (
    <>
      <HStack>
        <Button
          px='0'
          leftIcon={<Icon icon='arrow_right' transform='rotate(180deg)' />}
          onClick={() => setActiveFolderId(null)}
          variant='text'
        >
          {t('general.backToOverview')}
        </Button>
      </HStack>
      <Heading as='h1' mt='2' mb='4' fontSize='3xl'>
        {currentFolder?.name}
      </Heading>

      <FilterTags />

      <Stack gap='6' pt='6'>
        {getSortableFavoriteGroup(
          t('dashboard.collection.search.colorTones'),
          'uni',
        )}
        {getSortableFavoriteGroup(
          t('dashboard.collection.search.wallCoverings'),
          'wall',
        )}
        {getSortableFavoriteGroup(
          t('dashboard.collection.search.floorCoverings'),
          'floor',
        )}
        {getSortableFavoriteGroup(
          t('dashboard.collection.search.facades'),
          'facade',
        )}
        <AuthenticationModal />
      </Stack>
    </>
  );
};
