import { HStack, IconButton } from '@chakra-ui/react';

import { AvailableIcons, Icon } from '$/components/common/Icon';
import { ModuleSendSelectAction } from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { WorkMode } from '$/services/usecases/editor/mapper/editorStatus';

export const RetouchColorModes = () => {
  const workMode = useEditorStore.useWorkMode();
  const { selectAction } = useEditorActions();

  const options: {
    icon: AvailableIcons;
    workMode: WorkMode;
    selectAction: ModuleSendSelectAction;
  }[] = [
    {
      icon: 'shape_rectangle',
      workMode: 'retouchPaint',
      selectAction: ModuleSendSelectAction.UseRetouchPaint,
    },
    {
      icon: 'gradient',
      workMode: 'retouchGradient',
      selectAction: ModuleSendSelectAction.UseRetouchGradient,
    },
    {
      icon: 'structure',
      workMode: 'retouchPattern',
      selectAction: ModuleSendSelectAction.UseRetouchPattern,
    },
  ];

  return (
    <HStack>
      {options.map((option) => {
        const isActive = workMode === option.workMode;

        return (
          <IconButton
            key={option.workMode}
            borderColor='border'
            aria-label=''
            icon={<Icon icon={option.icon} width='12px' />}
            isActive={isActive}
            onClick={() => selectAction(option.selectAction)}
            size='sm'
            variant='tertiary'
          />
        );
      })}
    </HStack>
  );
};
