import { Box, BoxProps, Button, HStack, useDisclosure } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ThemeSwitcher } from '$/components/common/ThemeSwitcher';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { ShareModal } from '$/components/core/Editor/AttributeSidebar/components/Share/ShareModal';
import { trackEvent } from '$/logger';
import { useSaveEditorProject } from '$/pages/EditorPage/hooks/useSaveEditorProject';
import { useSaveInspirationProject } from '$/pages/InspirationEditorPage/hooks/useSaveInspirationProject';

interface Props extends BoxProps {
  projectId?: string | null;
  type: 'inspiration' | 'editor';
}

export const ShareSection: FC<Props> = ({ projectId, type, ...props }) => {
  const { t } = useTranslation();
  const { hasPermission } = useAuthorization();
  const [, onSaveInspirationProject] = useSaveInspirationProject();
  const [, onSaveEditorProject] = useSaveEditorProject();

  const onOpenAuthModal = useAuthenticationStore.useOnModalOpen();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onClick = async () => {
    if (type === 'inspiration') {
      await onSaveInspirationProject();
    }

    if (type === 'editor') {
      onSaveEditorProject();
    }

    trackEvent(
      'ShareProjekt',
      'Click',
      type === 'editor' ? 'Editor' : 'Inspiration',
    );

    hasPermission('Project_Share')
      ? onOpen()
      : onOpenAuthModal('versionComparison');
  };

  return (
    <Box {...props}>
      <HStack justifyContent='space-between' w='full' h='8'>
        <Button h='full' fontSize='xs' fontWeight='normal' onClick={onClick}>
          {t('editor.shareButton')}
        </Button>

        <ThemeSwitcher />
      </HStack>

      {projectId && (
        <ShareModal
          isOpen={isOpen}
          onClose={onClose}
          projectId={projectId}
          type={type}
        />
      )}
    </Box>
  );
};
