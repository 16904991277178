import {
  ButtonProps,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { FavoriteButton } from '$/components/core/Collection/FavoriteButton';
import { AddSectionContextMenuItem } from '$/components/core/Collection/MaterialCard/AddSectionContextMenuItem';
import { LoginForFavoriteToastContent } from '$/components/core/Collection/MaterialCard/LoginForFavoriteToastContent';
import { MoveFavoriteMenu } from '$/components/core/Collection/MaterialCard/MoveFavoriteMenu';
import { SavedFavoriteToastContent } from '$/components/core/Collection/MaterialCard/SavedFavoriteToastContent';
import { useToast } from '$/hooks/useToast';
import { useFavoriteModal } from '$/pages/DashboardPages/pages/Favorite/hooks/useFavoriteModal';
import { MaterialType } from '$/services/mapper/uses';
import { Material } from '$/services/usecases/materials';
import { DEFAULTFOLDERNAME, useFavoriteStore } from '$/stores/useFavoriteStore';
import { useMaterialContextMenuStore } from '$/stores/useMaterialContextMenuStore';

export type MaterialCardEditMode = 'none' | 'favorite' | 'favoriteExtended';

interface Props extends ButtonProps {
  editMode: MaterialCardEditMode;
  material: Material;
  materialType?: MaterialType;
  menuLayer?: 'dashboard' | 'inspirationModal';
}

const getIcon = (
  editMode: MaterialCardEditMode,
  isFavorite: boolean = false,
) => {
  if (editMode === 'favorite') {
    return (
      <Icon
        icon={isFavorite ? 'check_icon' : 'add_big'}
        boxSize={isFavorite ? '15px' : '4'}
        color='text'
      />
    );
  }

  if (editMode === 'favoriteExtended') {
    return <Icon icon='three_dots' color='text' boxSize='15px' />;
  }
};

export const MaterialCardContextMenu: FC<Props> = ({
  editMode,
  material,
  materialType,
  menuLayer = 'dashboard',
  ...props
}) => {
  const { t } = useTranslation();

  const activeMaterialContextMenuId =
    useMaterialContextMenuStore.useActiveMaterialId();
  const activeMenuLayer = useMaterialContextMenuStore.useMenuLayer();
  const favorites = useFavoriteStore.useFavorites();
  const contextMaterialType = useMaterialContextMenuStore.useMaterialType();
  const setActiveMaterialContextMenuId =
    useMaterialContextMenuStore.useSetActiveMaterial();
  const addFavorite = useFavoriteStore.useAddFavorite();
  const openFavoriteModal = useFavoriteModal.useOpenFavoriteModal();
  const { hasPermission } = useAuthorization();
  const toast = useToast();

  const isFavorite = useFavoriteStore((state) =>
    state.isFavorite(material.uniqueKey),
  );

  const activeFolderId = useFavoriteStore.useActiveFolderId();

  const removeFavoriteFromFolder =
    useFavoriteStore.useRemoveFavoriteFromFolder();

  const onToggleFavorite = async () => {
    if (!hasPermission('Favorites_Select')) {
      toast(
        t('error.accountNecessary'),
        'error',
        t('favorites.loginForFavorites'),
        {
          customContent: <LoginForFavoriteToastContent />,
        },
      );
      return;
    }
    if (isFavorite) {
      openFavoriteModal(
        'deleteFavorite',
        material.uniqueKey,
        material.info,
        materialType,
        menuLayer,
      );
    } else {
      await addFavorite(material.uniqueKey, materialType, material.info);
      toast(t('favorites.favoriteSavedToastHeader'), 'success', '', {
        customContent: (
          <SavedFavoriteToastContent
            materialLabel={material.info}
            materialId={material.uniqueKey}
            materialType={materialType}
            menuLayer={menuLayer}
          />
        ),
      });
    }
  };

  if (editMode === 'none') return null;

  if (
    material.uniqueKey === activeMaterialContextMenuId &&
    materialType === contextMaterialType &&
    menuLayer === activeMenuLayer
  ) {
    return (
      <MoveFavoriteMenu
        favoriteId={material.uniqueKey}
        icon={getIcon(editMode, isFavorite)}
        materialType={materialType}
        menuLayer={menuLayer}
      />
    );
  }

  if (editMode === 'favorite') {
    return (
      <FavoriteButton
        aria-label={t('favorites.addFavorit')}
        icon={getIcon(editMode, isFavorite)}
        toggleFavorite={onToggleFavorite}
        {...props}
      />
    );
  }

  if (editMode === 'favoriteExtended') {
    const favoriteItem = favorites.find(
      (favorite) => favorite.materialId === material.uniqueKey,
    );

    return (
      <Menu isLazy placement='bottom-end'>
        <MenuButton
          as={IconButton}
          layerStyle='floatCardButton'
          icon={getIcon(editMode, isFavorite)}
          onClick={(event) => {
            event.stopPropagation();
          }}
          variant='text'
          {...props}
        />
        <Portal>
          <MenuList fontSize='sm'>
            <MenuItem
              icon={<Icon icon='trash_can' />}
              onClick={async (event) => {
                event.stopPropagation();
                if (favoriteItem?.parentFolderIds.length === 1) {
                  openFavoriteModal(
                    'deleteFavorite',
                    material.uniqueKey,
                    material.info,
                    materialType,
                  );
                } else {
                  await removeFavoriteFromFolder(
                    material.uniqueKey,
                    activeFolderId ?? DEFAULTFOLDERNAME,
                  );
                }
              }}
            >
              {t('favorites.removeFromFolder')}
            </MenuItem>
            <MenuItem
              icon={<Icon icon='folder' />}
              onClick={(event) => {
                setActiveMaterialContextMenuId(
                  material.uniqueKey,
                  materialType,
                  menuLayer,
                );
                event.stopPropagation();
              }}
            >
              {t('favorites.changeFolder')}
            </MenuItem>
            <AddSectionContextMenuItem
              material={material}
              materialType={materialType}
            />
          </MenuList>
        </Portal>
      </Menu>
    );
  }
};
