import { BrightnessFilter, FilterGroup } from '$/services/usecases/filter';

export const filterGroupsToQuery = (
  filter: FilterGroup[] | undefined,
  brightnessFilter?: BrightnessFilter,
) => {
  const filterKeys =
    filter?.map((filter) => {
      if (filter.type.includes('.')) return filter.type.split('.')[1];
      else return filter.type;
    }) ?? [];

  const filterValues = filter?.map((filter) => filter.option) ?? [];
  if (
    brightnessFilter != null &&
    (brightnessFilter.from !== 0 || brightnessFilter.to !== 100)
  ) {
    filterKeys.push('hbw_min');
    filterKeys.push('hbw_max');
    filterValues.push(brightnessFilter.from.toString());
    filterValues.push(brightnessFilter.to.toString());
  }

  return [filterKeys.join(';'), filterValues.join(';')] as const;
};
