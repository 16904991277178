import { IconButton } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { ModuleSendBrushShapeAction } from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { BrushType } from '$/services/usecases/editor/mapper/editorStatus';

interface Props {
  mode: ModuleSendBrushShapeAction;
  type: BrushType;
  activeType: BrushType;
}

export const BrushShapeOptionButton: FC<Props> = ({
  type,
  mode,
  activeType,
}) => {
  const { t } = useTranslation();
  const { setBrushShape } = useEditorActions();

  const isActive = type === activeType;

  return (
    <IconButton
      border='1px solid'
      borderColor='border'
      aria-label={t(
        type === 'round' ? 'editor.circle' : 'editor.rectangle.title',
      )}
      icon={
        <Icon icon={type === 'round' ? 'shape_circle' : 'shape_rectangle'} />
      }
      isActive={isActive}
      onClick={() => setBrushShape(mode, type)}
      size='sm'
      variant='tertiary'
    />
  );
};
