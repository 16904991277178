import { object, z } from 'zod';

import { GeneralBackendResponseSchema } from '$/services/mapper/authentication';
import { MaterialTypeSchema } from '$/services/mapper/uses';

export const FavoriteSchema = z.object({
  materialId: z.string(),
  parentFolderIds: z.array(z.string()),
  isGlobalFavoriteMaterial: z.boolean(),
});

export const FavoriteFolderSectionSchema = z.object({
  id: z.string(),
  title: z.string().optional(),
  materialType: MaterialTypeSchema,
  favoriteIds: z.array(z.string()),
});

export type FavoriteFolderSection = z.infer<typeof FavoriteFolderSectionSchema>;

export const FavoriteFolderSchema = z.object({
  name: z.string(),
  id: z.string(),
  isGlobalFavoriteFolder: z.boolean().default(false),
  hasThumbnail: z.boolean().default(false),
  materialOrder: z.string().array().default([]),
  sections: z.array(FavoriteFolderSectionSchema).optional(),
});

export const GetFavoritesResponseSchema = object({
  ...GeneralBackendResponseSchema,
  payload: object({
    favoriteFolders: z.array(FavoriteFolderSchema),
    globalFavoriteFolders: z.array(FavoriteFolderSchema),
    favoriteMaterials: z.array(FavoriteSchema),
  }).optional(),
});

export const CreateFavoriteFolderResponseSchema = object({
  ...GeneralBackendResponseSchema,
  payload: FavoriteFolderSchema.optional(),
});

export type GetFavoritesResponse = z.infer<typeof GetFavoritesResponseSchema>;
