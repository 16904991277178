import { Box, Grid } from '@chakra-ui/react';
import { FC } from 'react';

import { For } from '$/components/common/Flow/For';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { LoadingMaterialCard } from '$/components/core/Collection/LoadingMaterialCard';
import { MaterialCard } from '$/components/core/Collection/MaterialCard';
import { MaterialCardEditMode } from '$/components/core/Collection/MaterialCard/MaterialCardContextMenu';
import { useMaterialDetailStore } from '$/components/core/Collection/MaterialDetailsModal/store/useMaterialDetailStore';
import { SectionHeadline } from '$/components/core/Collection/SectionHeadline';
import { Droppable } from '$/components/core/DragAndDrop/Droppable';
import { SortableContext } from '$/components/core/DragAndDrop/SortableContext';
import { SortableItem } from '$/components/core/DragAndDrop/SortableItem';
import { useMaterialGridMaxItems } from '$/hooks/useMaterialGridMaxItems';
import { FavoriteItem } from '$/pages/DashboardPages/pages/Favorite';
import { FolderSectionTitle } from '$/pages/DashboardPages/pages/Favorite/components/FolderSectionTitle';
import { MaterialType } from '$/services/mapper/uses';
import { FavoriteSectionGroup } from '$/stores/useFavoriteStore';
import { materialTemplateColumn } from '$/theme/utils/templateColumn';
import { repeat } from '$/utils/arrayUtils';

interface Props {
  isLoading: boolean;
  headline: string;
  materialType: MaterialType;
  editMode?: MaterialCardEditMode;
  isDragDisabled?: boolean;
  setMoveMode?: (isPaste: boolean) => void;
  folderSections?: FavoriteSectionGroup[];
}

export const FavoriteSortableGroup: FC<Props> = ({
  isLoading,
  headline,
  materialType,
  editMode,
  setMoveMode,
  isDragDisabled,
  folderSections,
}) => {
  const setActiveMaterial = useMaterialDetailStore.useSetActive();
  const itemLimit = useMaterialGridMaxItems();
  const { hasPermission } = useAuthorization();
  const openAuthenticationModal = useAuthenticationStore.useOnModalOpen();

  const favorites =
    folderSections?.flatMap((section) => section.favorites) ?? [];

  if (isLoading)
    return (
      <SectionHeadline headline={headline}>
        <Grid gap='6' templateColumns={materialTemplateColumn()}>
          {repeat(itemLimit).map((i) => (
            <LoadingMaterialCard key={i} />
          ))}
        </Grid>
      </SectionHeadline>
    );

  if (folderSections == null) return;

  const itemSection = (
    sectionId: string,
    items: FavoriteItem[],
    sectionTitle?: string,
  ) => {
    return (
      <>
        {sectionTitle && (
          <FolderSectionTitle
            sectionId={sectionId}
            sectionTitle={sectionTitle}
            materialType={materialType}
            isDisabled={isDragDisabled}
          />
        )}
        <Droppable width='full' minH='16' id={sectionId}>
          <SortableContext
            itemIds={items.map((item) => item.material.uniqueKey)}
            isDropDisabled={!!isDragDisabled}
          >
            <Grid gap='6' templateColumns={materialTemplateColumn()}>
              <For each={items}>
                {(favorite) => {
                  return (
                    <SortableItem
                      id={favorite.material.uniqueKey}
                      key={favorite.material.uniqueKey}
                      isDisabled={!!isDragDisabled}
                    >
                      <Box key={favorite.material.id}>
                        <MaterialCard
                          material={favorite.material}
                          onMouseDown={(event) =>
                            setMoveMode &&
                            setMoveMode(event.ctrlKey || event.metaKey)
                          }
                          onMouseUp={() => setMoveMode && setMoveMode(false)}
                          onClick={() => {
                            if (
                              !hasPermission('Use_Pro_Materials') &&
                              favorite.material.isProMaterial
                            ) {
                              openAuthenticationModal('versionComparison');
                            } else {
                              setActiveMaterial(favorite.material);
                            }
                          }}
                          editMode={editMode}
                          materialType={materialType}
                        />
                      </Box>
                    </SortableItem>
                  );
                }}
              </For>
            </Grid>
          </SortableContext>
        </Droppable>
      </>
    );
  };

  return (
    <SectionHeadline headline={headline}>
      <div id={`${materialType}-group`}>
        <SortableContext
          itemIds={favorites.map((favorite) => favorite.material.uniqueKey)}
          isDropDisabled={!!isDragDisabled}
          id={materialType}
        >
          {folderSections.map((section) => (
            <Box key={section.id}>
              {itemSection(section.id, section.favorites, section.title)}
            </Box>
          ))}
        </SortableContext>
      </div>
    </SectionHeadline>
  );
};
