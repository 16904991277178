import { Button, Stack, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { RotateIcon } from '$/components/common/Icon/RotateIcon';
import { AttributeHeader } from '$/components/core/Editor/AttributeHeader';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { LayerRotation } from '$/services/usecases/projects/mapper/inspirationProjects';

const directions: LayerRotation[] = [
  'vertical',
  'horizontal',
  'diagonal-right',
  'diagonal-left',
];

export const RotatorSelector = () => {
  const { t } = useTranslation();
  const activeComponentId = useInspirationEditorStore.useActiveComponentId();
  const activeComponent = useInspirationEditorStore((state) =>
    state.sceneLayersVariants[state.activeSceneVariantIndex].find(
      (layer) => layer.id === activeComponentId,
    ),
  );

  const changeRotationFloor =
    useInspirationEditorStore.useChangeRotationFloor();

  const handleRotationChange = (direction: LayerRotation) => {
    changeRotationFloor(direction);
  };

  return (
    <Stack>
      <AttributeHeader
        icon={<Icon boxSize='4' icon='floor_material' />}
        label={t('editor.layerDirection')}
      />

      <HStack gap='2'>
        {directions.map((direction) => (
          <Button
            key={direction}
            w='12'
            h='8'
            isActive={activeComponent?.rotation === direction}
            onClick={() => handleRotationChange(direction)}
            variant='tertiary'
          >
            <RotateIcon direction={direction} />
          </Button>
        ))}
      </HStack>
    </Stack>
  );
};
