import { Stack, Button, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';

export const NoResultCard = () => {
  const { t } = useTranslation();

  return (
    <Stack
      mx='4'
      px='4'
      py='3'
      bg='card'
      border='1px solid'
      borderColor='border'
      borderRadius='8px'
    >
      <Text color='lighterText' fontSize='xs' textAlign='center'>
        {t('inspirationEditor.search.noResults')}
      </Text>
      <Button
        color='lighterText'
        fontSize='xs'
        onClick={() => useMaterialSearchStore.setState({ query: '' })}
        variant='ghost'
      >
        {t('inspirationEditor.search.reset')}
      </Button>
    </Stack>
  );
};
