import {
  DndContext,
  rectIntersection,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  CollisionDetection,
  DragMoveEvent,
  DragOverEvent,
  Modifier,
} from '@dnd-kit/core';
import {
  restrictToFirstScrollableAncestor,
  restrictToHorizontalAxis,
  restrictToVerticalAxis,
  restrictToWindowEdges,
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { FC, ReactNode } from 'react';

import { restrictToBoundingRect } from '$/utils/dndUtils';

type DnDModifier =
  | 'limitToVerticalAxis'
  | 'limitToHorizontalAxis'
  | 'restrictToWindowEdges'
  | 'restrictToFirstScrollableAncestor'
  | 'restrictToParentElement';

interface Props {
  onDragEnd: (event: DragEndEvent) => void;
  onDragStart?: (event: DragStartEvent) => void;
  onDragMove?: (event: DragMoveEvent) => void;
  onDragOver?: (event: DragOverEvent) => void;
  children: ReactNode;
  dragPlaceholder?: ReactNode;
  modifiers?: DnDModifier[];
  collisionDetection?: CollisionDetection;
  useKeyboardSensor?: boolean;
  restrictToSpecificParentId?: string;
}

const loadModifiers = (modifiers: DnDModifier[]) => {
  const loadedModifiers = [];

  if (modifiers.includes('limitToVerticalAxis')) {
    loadedModifiers.push(restrictToVerticalAxis);
  }

  if (modifiers.includes('limitToHorizontalAxis')) {
    loadedModifiers.push(restrictToHorizontalAxis);
  }

  if (modifiers.includes('restrictToWindowEdges')) {
    loadedModifiers.push(restrictToWindowEdges);
  }

  if (modifiers.includes('restrictToFirstScrollableAncestor')) {
    loadedModifiers.push(restrictToFirstScrollableAncestor);
  }

  if (modifiers.includes('restrictToParentElement')) {
    loadedModifiers.push(restrictToParentElement);
  }

  return loadedModifiers;
};

export const DnDContext: FC<Props> = ({
  onDragEnd,
  onDragStart,
  onDragMove,
  onDragOver,
  children,
  dragPlaceholder,
  modifiers = [],
  collisionDetection = rectIntersection,
  useKeyboardSensor = true,
  restrictToSpecificParentId,
}) => {
  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: sortableKeyboardCoordinates,
  });

  const restrictToSpecificParentElementModifier: Modifier = ({
    draggingNodeRect,
    transform,
  }) => {
    if (!draggingNodeRect || !restrictToSpecificParentId) {
      return transform;
    }

    const parentElement = document.getElementById(restrictToSpecificParentId);

    if (!parentElement) {
      return transform;
    }

    const parentElementRect = parentElement.getBoundingClientRect();

    return restrictToBoundingRect(
      transform,
      draggingNodeRect,
      parentElementRect,
    );
  };

  const usedModifiers = loadModifiers(modifiers);

  if (restrictToSpecificParentId != null)
    usedModifiers.push(restrictToSpecificParentElementModifier);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { delay: 500, distance: 0 },
    }),
    useKeyboardSensor ? keyboardSensor : undefined,
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetection}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      modifiers={usedModifiers}
      onDragMove={onDragMove}
      onDragOver={onDragOver}
    >
      {children}
      {dragPlaceholder && <DragOverlay>{dragPlaceholder}</DragOverlay>}
    </DndContext>
  );
};
