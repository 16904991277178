import { IconButton } from '@chakra-ui/react';
import { TFunction } from 'i18next';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AvailableIcons, Icon } from '$/components/common/Icon';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { MagicModeOptions } from '$/services/usecases/editor/mapper/editorStatus';

const getProperties = (
  mode: MagicModeOptions,
  t: TFunction<'translation', undefined>,
): { label: string; icon: AvailableIcons } => {
  switch (mode) {
    case 'new':
      return {
        icon: 'magic_wand',
        label: t('editor.magicWandNew'),
      };
    case 'add':
      return {
        icon: 'magic_wand_add',
        label: t('editor.magicWandAdd'),
      };
    case 'sub':
      return {
        icon: 'magic_wand_subtract',
        label: t('editor.magicWandSubtract'),
      };
  }
};

interface Props {
  mode: MagicModeOptions;
  iconBoxSize?: string;
}

export const MagicWandModeOptionButton: FC<Props> = ({
  mode,
  iconBoxSize = '16px',
}) => {
  const currentMode = useEditorStore.useMagicMode();
  const { setMagicMode } = useEditorActions();
  const { t } = useTranslation();
  const isActive = mode === currentMode;

  const { icon, label } = getProperties(mode, t);

  const onSelect = () => {
    setMagicMode(mode);
  };

  return (
    <IconButton
      w='full'
      h='8'
      px='3'
      py='2'
      border='1px solid'
      borderColor='border'
      aria-label={label}
      icon={<Icon icon={icon} boxSize={iconBoxSize} />}
      isActive={isActive}
      onClick={onSelect}
      title={label}
      variant='tertiary'
    />
  );
};
