import { MenuItem } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { MaterialType } from '$/services/mapper/uses';
import { changeFolderOrder } from '$/services/usecases/favorites';
import { Material } from '$/services/usecases/materials';
import {
  FavoriteSectionGroup,
  useFavoriteStore,
} from '$/stores/useFavoriteStore';
import { uid } from '$/utils/uid';

interface Props {
  material: Material;
  materialType?: MaterialType;
}

export const AddSectionContextMenuItem: FC<Props> = ({
  material,
  materialType,
}) => {
  const { t } = useTranslation();
  const getFavorites = useFavoriteStore.useGetFavorites();
  const folders = useFavoriteStore.useFavoriteFolders();
  const activeFolderId = useFavoriteStore.useActiveFolderId();
  const activeFolder = folders.find((folder) => folder.id === activeFolderId);
  const folderSectionGroups = useFavoriteStore.useFolderSectionGroups();

  const getMaterialPosition = (
    groups: Partial<Record<MaterialType, FavoriteSectionGroup[]>>,
  ) => {
    if (materialType == null)
      return { materialSectionIndex: -1, materialIndex: -1 };

    const typeSections = groups[materialType] ?? [];

    const materialSectionIndex = typeSections.findIndex((section) =>
      section.favorites.some(
        (m) => m.material.uniqueKey === material.uniqueKey,
      ),
    );

    if (materialSectionIndex == null && materialSectionIndex === -1) {
      return { materialSectionIndex: -1, materialIndex: -1 };
    }

    const materialIndex = typeSections[
      materialSectionIndex
    ].favorites.findIndex((m) => m.material.uniqueKey === material.uniqueKey);

    return { materialSectionIndex, materialIndex };
  };

  const onCreateSection = async () => {
    if (
      activeFolder == null ||
      materialType == null ||
      Object.keys(folderSectionGroups).length === 0
    )
      return;

    const newGroups = { ...folderSectionGroups };

    const typeSections = newGroups[materialType] ?? [];

    const { materialSectionIndex, materialIndex } =
      getMaterialPosition(newGroups);

    const materialsForNewSection =
      typeSections[materialSectionIndex].favorites.slice(materialIndex);

    const newSection = {
      title:
        `${t('favorites.newSectionName')} ${typeSections.length > 0 ? typeSections.length : ''}`.trim(),
      favorites: materialsForNewSection,
      materialType,
      id: uid(),
    };

    newGroups[materialType]![materialSectionIndex] = {
      ...newGroups[materialType]![materialSectionIndex],
      favorites: newGroups[materialType]![materialSectionIndex].favorites.slice(
        0,
        materialIndex,
      ),
    };

    newGroups[materialType]!.splice(materialSectionIndex + 1, 0, newSection);

    const allSections = Object.values(newGroups)
      .flat()
      .map((group) => ({
        title: group.title,
        materialType: group.materialType,
        favoriteIds: group.favorites.map((m) => m.material.uniqueKey),
        id: group.id,
      }));

    await changeFolderOrder(
      activeFolder.id,
      activeFolder.materialOrder,
      allSections,
    );
    await getFavorites(true);
  };

  const { materialIndex } = getMaterialPosition(folderSectionGroups);

  if (materialIndex === 0) return null;

  return (
    <MenuItem
      icon={<Icon icon='add_section' />}
      onClick={async (event) => {
        event.stopPropagation();
        await onCreateSection();
      }}
    >
      {t('favorites.addSection')}
    </MenuItem>
  );
};
