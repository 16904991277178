let BUFFER: string;
const HEX: string[] = [];
let IDX = 256;
const SIZE = 256;

while (IDX--) HEX[IDX] = (IDX + 256).toString(16).substring(1);

export const uid = (len = 11) => {
  let i = 0;

  if (!BUFFER || IDX + len > SIZE * 2) {
    BUFFER = '';
    IDX = 0;
    for (i = 0; i < SIZE; i++) {
      BUFFER += HEX[(Math.random() * 256) | 0];
    }
  }

  return BUFFER.substring(IDX, (IDX += len));
};
