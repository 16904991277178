import {
  useDisclosure,
  Stack,
  Text,
  Button,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { HookFormDropdownInput } from '$/components/core/Form/HookFormDropdownInput';
import { BrandCountry } from '$/services/usecases/admin/mapper/brandCountries';

type UpdateInspirationData = {
  query: string;
};

interface Props {
  selectedBrandCountries: BrandCountry[];
  setSelectedBrandCountries: Dispatch<SetStateAction<BrandCountry[]>>;
  allBrandCountries: BrandCountry[];
  descriptionText: string;
}

export const BrandCountrySelection: FC<Props> = ({
  allBrandCountries,
  selectedBrandCountries,
  setSelectedBrandCountries,
  descriptionText,
}) => {
  const { t } = useTranslation();
  const formMethods = useForm<UpdateInspirationData>();

  const {
    isOpen: isDropdownOpen,
    onOpen: onDropdownOpen,
    onClose: onDropdownClose,
    onToggle: onDropdownToggle,
  } = useDisclosure();

  const query = formMethods.watch('query');

  useEffect(() => {
    if (query?.length > 0) onDropdownOpen();
  }, [query, onDropdownOpen]);

  const filteredBrandCountries =
    query?.length < 1
      ? allBrandCountries.filter(
          (brandCountry) =>
            !selectedBrandCountries.some(
              (selected) => brandCountry.id === selected.id,
            ),
        )
      : allBrandCountries.filter(
          (brandCountry) =>
            !selectedBrandCountries.some(
              (selected) => brandCountry.id === selected.id,
            ) &&
            (brandCountry.id.includes(query) ||
              t(`countries.${brandCountry.id}`)
                .toLowerCase()
                .includes(query?.toLowerCase())),
        );

  const onRemoveBrandCountry = (id: string) => {
    setSelectedBrandCountries((prev) =>
      prev.filter((brandCountry) => brandCountry.id !== id),
    );
  };

  return (
    <FormProvider {...formMethods}>
      <HookFormDropdownInput<UpdateInspirationData>
        dropdownOpen={isDropdownOpen}
        toggleDropdown={() => onDropdownToggle()}
        accessor='query'
        placeholder={t('admin.inspirations.editModal.countryInputPlaceholder')}
        label={t('admin.inspirations.editModal.selectCountriesDescription')}
        onBlur={() => onDropdownClose()}
        dropdownContent={() => {
          return (
            <Stack pt='2' pb='6px'>
              {filteredBrandCountries?.length === 0 && (
                <Text px='4' py='6px' color='lighterText' fontSize='sm'>
                  {t('admin.languages.addLanguage.noResults')}
                </Text>
              )}
              {filteredBrandCountries?.map((brandCountry) => (
                <Button
                  key={brandCountry.id}
                  px='4'
                  py='6px'
                  fontSize='sm'
                  fontWeight='normal'
                  textAlign='left'
                  _hover={{ bg: 'bodyBackground' }}
                  cursor='pointer'
                  onClick={() => {
                    setSelectedBrandCountries((prev) => [
                      ...prev,
                      brandCountry,
                    ]);
                    setTimeout(() => onDropdownClose(), 0);
                  }}
                  type='button'
                  variant='unstyled'
                >
                  <HStack>
                    <Text>
                      {t(`countries.${brandCountry.id}`, {
                        defaultValue: brandCountry.id,
                      })}
                    </Text>
                  </HStack>
                </Button>
              ))}
            </Stack>
          );
        }}
      />
      <Text mt='2' mb='4' color='lighterText' fontSize='sm'>
        {descriptionText}
      </Text>

      <HStack flexWrap='wrap'>
        {selectedBrandCountries.map((brandCountry) => (
          <HStack
            key={brandCountry.id}
            px='4'
            py='2'
            border='1px solid'
            borderColor='border'
            borderRadius='full'
          >
            <Icon
              icon={brandCountry.isBrand ? 'loyalty_tag' : 'country_globe'}
              boxSize='4'
              color='lighterText'
            />
            <Text>
              {t(`countries.${brandCountry.id}`, {
                defaultValue: brandCountry.id,
              })}
            </Text>
            <IconButton
              boxSize='3'
              minW='0'
              aria-label='Close'
              icon={<Icon icon='close' boxSize='3' color='lighterText' />}
              onClick={() => onRemoveBrandCountry(brandCountry.id)}
              variant='ghost'
            />
          </HStack>
        ))}
      </HStack>
    </FormProvider>
  );
};
