import { Box, Stack, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { For } from '$/components/common/Flow/For';
import { Show } from '$/components/common/Flow/Show';
import { MaterialListItem } from '$/components/core/Collection/MaterialListItem';
import { MaterialListItemSkeleton } from '$/components/core/Collection/MaterialListItemSkeleton';
import { SearchInput } from '$/components/core/Collection/MaterialSearch/SearchInput';
import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';
import { NoResultCard } from '$/components/core/Editor/ColorSelectionSidebar/SearchResult/components/NoResultCard';
import { SidebarBackButton } from '$/components/core/Editor/ColorSelectionSidebar/SidebarBackButton';
import { SidebarDivider } from '$/components/core/Editor/SidebarDivider';
import { usePiwikSearchTracking } from '$/hooks/usePiwikSearchTracking';
import { Material } from '$/services/usecases/materials';
import { materialsQuery } from '$/services/usecases/materials/queries';
import { repeat } from '$/utils/arrayUtils';

interface Props {
  onHandleResultClick: (material: Material) => void;
}

export const SearchResult: FC<Props> = ({ onHandleResultClick }) => {
  const { t } = useTranslation();

  const query = useMaterialSearchStore.useQuery();

  const { data, isLoading } = useQuery(materialsQuery({ query }));

  usePiwikSearchTracking(data);

  return (
    <Stack gap='0' pb='5'>
      <SidebarBackButton
        onClick={() => useMaterialSearchStore.setState({ query: '' })}
        label={t('inspirationEditor.attributes.colorSelection')}
      />

      <SidebarDivider />
      <Box h='8' mx='4' mt='3'>
        <SearchInput
          searchLabel={t('inspirationEditor.attributes.search')}
          useAutofocus={true}
        />
      </Box>

      <Text
        px='4'
        pt='7'
        pb='5'
        color='lighterText'
        fontSize='sm'
        fontWeight='bold'
      >
        {t('inspirationEditor.filter.searchResults', {
          amount: data?.materialCount,
        })}
      </Text>

      <Show when={!data?.materialCount && !isLoading}>
        <NoResultCard />
      </Show>

      <Stack gap='3' overflowY='auto' ml='4' px='4'>
        <For
          each={data?.materials}
          fallback={() =>
            isLoading &&
            repeat(50).map((i) => <MaterialListItemSkeleton key={i} id={i} />)
          }
        >
          {(material, i) => (
            <Box cursor='pointer' onClick={() => onHandleResultClick(material)}>
              <MaterialListItem id={i} material={material} />
            </Box>
          )}
        </For>
      </Stack>
    </Stack>
  );
};
