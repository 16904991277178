import { VStack, Text, HStack, Switch, Box } from '@chakra-ui/react';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { FileInput } from '$/pages/EditorPage/components/LoadModal/FileInput';
import { useFavoriteStore } from '$/stores/useFavoriteStore';

interface Props {
  itemIdentifier: string;
  file: File | null;
  onFileSet: Dispatch<SetStateAction<File | null>>;
  setSubmitDisabled: Dispatch<SetStateAction<boolean>>;
}

export const GlobalFavoritePublishModalContent: FC<Props> = ({
  file,
  onFileSet,
  itemIdentifier,
  setSubmitDisabled,
}) => {
  const { t } = useTranslation();
  const folders = useFavoriteStore.useFavoriteFolders();
  const [showInput, setShowInput] = useState(false);

  return (
    <VStack>
      <Text whiteSpace='pre-line'>
        <Trans
          i18nKey='favorites.publishModal.description'
          values={{
            folderName: folders.find((folder) => folder.id === itemIdentifier)
              ?.name,
          }}
        />
      </Text>
      <HStack justify='space-between' w='full' h='6' my='4'>
        <Text>{t('favorites.publishModal.addBackgroundImage')}</Text>
        <Switch
          checked={showInput}
          onChange={(e) => {
            setShowInput(e.target.checked);
            setSubmitDisabled(e.target.checked);
            if (!e.target.checked) onFileSet(null);
          }}
        />
      </HStack>
      {showInput && (
        <Box w='full'>
          <HStack justifyContent='space-between' w='full'>
            <Box w='full' mb='2' fontWeight='bold'>
              {t('editor.upload_picture')}
            </Box>
          </HStack>

          <FileInput
            selectedFile={file}
            setSelectedFile={(e) => {
              onFileSet(e);
              setSubmitDisabled(false);
            }}
          />
        </Box>
      )}
    </VStack>
  );
};
