import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Button,
  HStack,
  Stack,
  Grid,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FlagButton } from '$/components/core/Localization/FlagButton';
import { useCountryLanguageOptions } from '$/components/core/Localization/hooks/useCountryLanguageOptions';
import { useLocalizationStore } from '$/components/core/Localization/hooks/useLocalizationStore';
import { mapLanguageCodeToFlagCode } from '$/utils/i18Utils';

export const LocalizationModal = () => {
  const { t } = useTranslation();

  const isOpen = useLocalizationStore.useIs18nModalOpen();
  const onClose = useLocalizationStore.useOnClose18nModal();
  const applyLocalization = useLocalizationStore.useApplyLocalization();

  const isInitialSelection = useLocalizationStore.useIsInitialSelection();
  const selectedBrand = useLocalizationStore.useBrand();

  const [selectedCountryId, setSelectedCountryId] = useState(
    useLocalizationStore.getState().country,
  );
  const [selectedLanguageId, setSelectedLanguageId] = useState(
    selectedBrand ?? useLocalizationStore.getState().language,
  );

  const { countries, languages } = useCountryLanguageOptions(selectedCountryId);

  useEffect(() => {
    if (selectedBrand != null) {
      setSelectedCountryId(selectedBrand);
      return;
    }

    if (languages && !languages.includes(selectedLanguageId)) {
      setSelectedLanguageId(languages[0]);
    }
  }, [languages, selectedLanguageId, selectedBrand]);

  const applyChanges = async () => {
    await applyLocalization({
      country: selectedCountryId,
      language: selectedLanguageId,
    });

    onClose();
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent gap='6' maxW='1000px' p='10'>
        <ModalHeader p='0'>
          <ModalCloseButton top='10' right='10' />

          <Text pt='1' fontSize='h7'>
            {!selectedBrand
              ? t('localization.locationAndLanguage')
              : t('localization.languages')}
          </Text>

          {isInitialSelection && (
            <Text mt='4' fontSize='lg' fontWeight='normal'>
              {t('localization.checkLanguageBrandSelection')}
            </Text>
          )}
        </ModalHeader>

        <ModalBody as={Stack} gap='10' p='0'>
          <Stack gap='6'>
            {!selectedBrand && (
              <Stack>
                <Text fontSize='lg'>{t('localization.location')}</Text>
                <Grid
                  templateColumns={{
                    base: 'repeat(1,minmax(0, 1fr))',
                    mobile: 'repeat(2,minmax(0, 1fr))',
                    sm: 'repeat(4,minmax(0, 1fr))',
                  }}
                  ml='-2'
                >
                  {countries
                    ?.filter((country) => !country.isBrand)
                    .map((country) => (
                      <FlagButton
                        key={country.id}
                        onClick={(id) => setSelectedCountryId(id)}
                        isActive={country.id === selectedCountryId}
                        id={country.id}
                        type='country'
                      />
                    ))}
                </Grid>
              </Stack>
            )}

            <Stack>
              {!selectedBrand && (
                <Text fontSize='lg'>{t('localization.languages')}</Text>
              )}
              <Grid
                templateColumns={{
                  base: 'repeat(1,minmax(0, 1fr))',
                  mobile: 'repeat(2,minmax(0, 1fr))',
                  sm: 'repeat(4,minmax(0, 1fr))',
                }}
                ml='-2'
              >
                {languages?.map((languageId) => (
                  <FlagButton
                    key={languageId}
                    onClick={(id) => setSelectedLanguageId(id)}
                    isActive={languageId === selectedLanguageId}
                    id={languageId}
                    flagIdOverwrite={mapLanguageCodeToFlagCode(languageId)}
                    type='language'
                  />
                ))}
              </Grid>
            </Stack>
          </Stack>

          <HStack justify='flex-end' w='full'>
            <Button
              color='lighterText'
              fontSize='sm'
              onClick={onClose}
              variant='ghost'
            >
              {t('general.cancel')}
            </Button>
            <Button
              px='6'
              py='3'
              fontSize='sm'
              onClick={applyChanges}
              type='submit'
              variant='primary'
            >
              {t('general.apply')}
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
