import { Button } from '@chakra-ui/react';
import { FC } from 'react';

interface Props {
  unit: 'cm' | 'm';
  activeUnit: 'cm' | 'm';
  onClick: (unit: 'cm' | 'm') => void;
}

export const UnitOption: FC<Props> = ({ unit, activeUnit, onClick }) => {
  const isActive = unit === activeUnit;

  return (
    <Button
      w='49px'
      h='32px'
      color='text'
      fontSize='sm'
      fontWeight='normal'
      border='1px solid'
      borderColor='border'
      isActive={isActive}
      onClick={() => onClick(unit)}
      variant='tertiary'
    >
      {unit}
    </Button>
  );
};
