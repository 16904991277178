import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  MenuItem,
  Portal,
  MenuGroup,
} from '@chakra-ui/react';
import { Link } from '@tanstack/react-router';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Show } from '$/components/common/Flow/Show';
import { GhostButton } from '$/components/common/GhostButton';
import { Icon } from '$/components/common/Icon';
import { ThemeSwitcher } from '$/components/common/ThemeSwitcher';
import { useLocalizationStore } from '$/components/core/Localization/hooks/useLocalizationStore';
import { StyledLink } from '$/components/core/StyledLink';
import { trackContentImpression, trackContentInteraction } from '$/logger';

interface Props {
  height?: string;
  showHomeLink?: boolean;
}

export const BrandMenu: FC<Props> = ({ height, showHomeLink = true }) => {
  const { t } = useTranslation();

  const onOpen18nModal = useLocalizationStore.useOnOpen18nModal();

  return (
    <>
      <Menu closeOnSelect={false}>
        {({ isOpen, onClose }) => (
          <>
            <Flex align='center' h='full'>
              <Link to='/'>
                <Icon icon='caparol' width='28px' height='26px' />
              </Link>

              <MenuButton
                as={GhostButton}
                h={height}
                color='header.text'
                fontSize='sm'
                onClick={() => {
                  trackContentImpression('MainMenu', 'BrandMenu', 'Show');
                }}
                rightIcon={
                  <Icon
                    transition='transform 0.3s ease'
                    transform={`rotate(${isOpen ? '180deg' : '0deg'})`}
                    icon='chevron_down'
                    w='12px'
                  />
                }
              >
                {t('brand.spectrum')}
              </MenuButton>
            </Flex>

            <Portal>
              <MenuList fontSize='sm' borderColor='border'>
                <Show when={showHomeLink}>
                  <MenuItem>
                    <StyledLink link={{ to: '/' }} w='full'>
                      {t('dashboard.header.toStart')}
                    </StyledLink>
                  </MenuItem>
                  <MenuDivider />
                </Show>

                <MenuItem
                  h='fit-content'
                  fontSize='14px'
                  fontWeight='normal'
                  onClick={() => {
                    onOpen18nModal();
                    onClose();
                  }}
                >
                  {t('localization.locationAndLanguage')}
                </MenuItem>

                <MenuItem justifyContent='space-between' gap='3'>
                  <Box as='span'>{t('dashboard.header.theme')}</Box>
                  <Box h='32px' p='0'>
                    <ThemeSwitcher />
                  </Box>
                </MenuItem>

                {import.meta.env.VITE_NODE_ENV !== 'production' && (
                  <>
                    <MenuDivider />
                    <MenuGroup ml='3' title='Dokumentation'>
                      <MenuItem>
                        <Link to='/docs/api'>API Dokumentation</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to='/docs/technical'>
                          Technische Dokumentation
                        </Link>
                      </MenuItem>
                    </MenuGroup>
                  </>
                )}

                <MenuDivider />

                <MenuGroup title={t('dashboard.legal.title')}>
                  <MenuItem>
                    <StyledLink
                      link={{ to: '/imprint' }}
                      w='full'
                      onClick={() =>
                        trackContentInteraction(
                          'ClickButton',
                          'MainMenu',
                          'Imprint',
                          'Open',
                        )
                      }
                    >
                      {t('dashboard.legal.imprint')}
                    </StyledLink>
                  </MenuItem>

                  <MenuItem>
                    <StyledLink
                      link={{ to: '/integrity' }}
                      w='full'
                      onClick={() =>
                        trackContentInteraction(
                          'ClickButton',
                          'MainMenu',
                          'Integrity',
                          'Open',
                        )
                      }
                    >
                      {t('dashboard.legal.integrity')}
                    </StyledLink>
                  </MenuItem>

                  <MenuItem>
                    <StyledLink
                      link={{ to: '/privacy' }}
                      w='full'
                      onClick={() =>
                        trackContentInteraction(
                          'ClickButton',
                          'MainMenu',
                          'Privacy',
                          'Open',
                        )
                      }
                    >
                      {t('dashboard.legal.privacy')}
                    </StyledLink>
                  </MenuItem>

                  <MenuItem>
                    <StyledLink
                      link={{ to: '/tos' }}
                      w='full'
                      onClick={() =>
                        trackContentInteraction(
                          'ClickButton',
                          'MainMenu',
                          'Terms',
                          'Open',
                        )
                      }
                    >
                      {t('dashboard.legal.terms')}
                    </StyledLink>
                  </MenuItem>
                </MenuGroup>
              </MenuList>
            </Portal>
          </>
        )}
      </Menu>
    </>
  );
};
