import { Badge, Box, Flex, IconButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { GhostButton } from '$/components/common/GhostButton';
import { Icon } from '$/components/common/Icon';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { useFeedbackModal } from '$/hooks/useFeedbackModal';

export const Links = () => {
  const { t } = useTranslation();

  const { hasPermission } = useAuthorization();
  const onOpenFeedbackModal = useFeedbackModal.useOnOpen();

  const onOpenAuthenticationModal = useAuthenticationStore.useOnModalOpen();

  return (
    <Flex align='center' gap='3'>
      <Badge
        h='5'
        px='1'
        py='0.5'
        color='primaryButton.color'
        lineHeight='4'
        textTransform='none'
        bg='primaryButton.background'
      >
        Beta
      </Badge>

      <Box w='2px' h='50%' bg='header.divider' />

      <IconButton
        minW='0'
        aria-label='Feedback'
        icon={<Icon icon='message' />}
        onClick={onOpenFeedbackModal}
        variant='text'
      />

      {/* TODO: REMOVE IF TESTED ENOUGH */}
      {import.meta.env.VITE_NODE_ENV !== 'production' && (
        <>
          <Box w='2px' h='50%' bg='header.divider' />
          <IconButton
            minW='0'
            aria-label='SAP'
            icon={
              <svg
                width='32px'
                height='32px'
                viewBox='0 0 1024 1024'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle cx='512' cy='512' r='512' style={{ fill: '#007cc5' }} />
                <path
                  d='M256 359.88v304.24h310.82L871 359.88zm83.62 56.58h.14c22.11 0 49.06 6.29 68.56 16.22l-21 36.64C365.92 459 355.17 458 343 457.36c-18.14-1-27.26 5.51-27.4 12.5-.18 8.52 17.13 16.23 33.45 21.47 24.7 7.85 56.1 18.33 60.9 48.7l45.15-119.36h52.45l52.88 142.91-.2-142.86H621c57.84 0 85 19.58 85 64.53 0 40.36-28 64.42-75.25 64.42h-19.56l-.19 53.69-92-.09-6.38-23.27a101.65 101.65 0 0 1-31.56 4.83 100.52 100.52 0 0 1-32.32-5.12l-9.19 23.54-51.25.16 2.3-11.84c-.73.64-1.43 1.29-2.22 1.91-13.7 11-30.94 16.11-52.18 16.49h-5.48c-24.41 0-45.89-5.78-66.13-17.28l18.71-37.12c20.26 12 33 14.68 50.11 14.31 8.89-.18 15.32-1.8 19.52-6.13a14.29 14.29 0 0 0 4-9.45c.17-9.64-13.56-14.17-30.36-19.37-13.62-4.22-29.08-10-41.17-18.83-14.31-10.53-21.12-23.69-20.78-42.27a51 51 0 0 1 14.49-35.31c13.31-13.76 34.76-22.12 60.54-22.12zm271.55 42.8v48.8h13.35c17.78 0 32-5.92 32-24.72 0-18.16-14.19-24.09-32-24.09zm-130.32 19.65-19.17 60.54a54.92 54.92 0 0 0 19.38 3.39 56.79 56.79 0 0 0 19-3.22l-18.87-60.71z'
                  style={{ fill: '#fff' }}
                />
              </svg>
            }
            onClick={() => {
              // @ts-expect-error This is just for testing the sap connection
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
              gigya.accounts.showScreenSet({
                screenSet: 'CAPAROL_OIDC-RegistrationLogin',
                startScreen: 'gigya-login-screen',
              });
            }}
            onContextMenu={() => {
              const head = document.querySelector('head');

              if (head?.querySelector('#gigya-script') != null) {
                return;
              }

              const script = document.createElement('script');
              script.setAttribute(
                'src',
                'https://account.mycaparol.de/js/gigya.js?apikey=4_SOl2rRrmQeNBStwK2B86ig&lang=de',
              );
              script.setAttribute('type', 'text/javascript');
              script.setAttribute('id', 'gigya-script');
              head?.appendChild(script);
            }}
            variant='ghost'
          />
        </>
      )}

      <Box
        w='2px'
        h='50%'
        bg={hasPermission('Admin_Access') ? 'transparent' : 'header.divider'}
      />

      {hasPermission('General_Login_Register') && (
        <GhostButton
          onClick={() => onOpenAuthenticationModal('login')}
          rightIcon={<Icon icon='arrow_right' width='13px' />}
          h='full'
          fontSize='sm'
        >
          {t('dashboard.header.signIn')}
        </GhostButton>
      )}
    </Flex>
  );
};
