import {
  Menu,
  MenuList,
  MenuItem,
  Text,
  useDisclosure,
  MenuButton,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useFavoriteModal } from '$/pages/DashboardPages/pages/Favorite/hooks/useFavoriteModal';
import { MaterialType } from '$/services/mapper/uses';

interface Props {
  sectionId: string;
  sectionTitle: string;
  materialType: MaterialType;
  isDisabled?: boolean;
}

export const FolderSectionTitle: FC<Props> = ({
  materialType,
  sectionId,
  sectionTitle,
  isDisabled = true,
}) => {
  const { openFavoriteModal } = useFavoriteModal();
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Menu isOpen={isOpen} onClose={onClose}>
      <MenuButton
        onContextMenu={(e) => {
          if (isDisabled) return;
          e.preventDefault();
          onOpen();
        }}
      >
        <Text mt='6' mb='2' fontSize='md' fontWeight='bold'>
          {sectionTitle}
        </Text>
      </MenuButton>
      <MenuList fontSize='sm'>
        <MenuItem
          px='4'
          icon={<Icon icon='edit_pen' boxSize='3' />}
          onClick={(event) => {
            event.stopPropagation();
            openFavoriteModal(
              'renameSection',
              sectionId,
              sectionTitle,
              materialType,
            );
          }}
        >
          {t('favorites.renameSection')}
        </MenuItem>
        <MenuItem
          px='4'
          icon={<Icon icon='trash_can' boxSize='3' />}
          onClick={(event) => {
            event.stopPropagation();
            openFavoriteModal(
              'deleteSection',
              sectionId,
              sectionTitle,
              materialType,
            );
          }}
        >
          {t('favorites.deleteSection')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
